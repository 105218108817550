import React from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Slide from "../components/Slide"
import NavBar from "../components/NavBar"
import Jumbo from "../components/Jumbo"
import Footer from "../components/Footer"

export function Head() {
  return (
    <>
      <title>Asesorias y proyectos de energia y paneles solares</title>
      <meta
        name="description"
        content="Desarrollo de proyectos de energia solar para la transformacion de organizaciones para una mayor eficiencia energetica"
      />
      <meta
        name="keywords"
        content="Asesorias, Proyectos solares, proyectos paneles solares, Eficiencia Energetica, Asesorias proyectos solares, Transformacion Energetica, ESG"
      />
    </>
  )
}

class Asesorias extends React.Component {
  constructor(props) {
    super(props)
    this.click = this.click.bind(this)
  }

  click(e) {
    e.preventDefault()
    navigate("/contact")
  }

  render() {
    const project_planning = getImage(this.props.project_planning)
    const site_survey = getImage(this.props.site_survey)
    const engineering = getImage(this.props.engineering)
    const financing = getImage(this.props.financing)
    const audit = getImage(this.props.audit)

    return (
      <>
        <NavBar {...this.props} />

        <Jumbo jumbo={this.props.jumbo}>
          <h1>Asesorías</h1>
          <h2>Desarrollo de proyectos solares y eficiencia energética</h2>
        </Jumbo>
        <div className="copyright">
          <div className="col-md-12 col-xs-12 text-center">
            <a href="#trabajos">
              {" "}
              <i className="fa-solid fa-angle-down fa-bounce"></i>{" "}
            </a>
          </div>
        </div>

        <section id="asesorias">
          <div className="container">
            <Slide id="trabajos" nextSlide="#solucion">
              <div className="row">
                <div className="col-xl-12">
                  <div className="text-center">
                    <h2>Desarrollo, Planificación y Diseño</h2>
                    <h4>
                      Solar es sinónimo de inversión, y nosotros en GlobalAxxis,
                      ofrecemos una variedad de soluciones para compañías y
                      empresas de todos los tamaños
                    </h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <GatsbyImage
                    image={project_planning}
                    alt="Planificación y diseño paneles solares"
                  />
                </div>
                <div className="col-lg-8 col-md-6 col-12">
                  <p>
                    Proyectos que transformen organizaciones nunca son fáciles o
                    sencillos, requieren de la mejor planificación, diseño,
                    financiamiento y equipo de trabajo correcto. Y nosotros,
                    como líderes en soluciones solares comerciales, lo sabemos.
                  </p>
                  <p>
                    Nuestra experiencia en la industria solar es uno de nuestros
                    activos más valiosos. Hemos realizado instalaciones de
                    plantas generadoras de energía solar en diversos sitios,
                    fabricantes y presupuestos. Las metas de energía específicas
                    de nuestros clientes y sus respectivos niveles de
                    financiamientos necesitan de un diseño personalizado, y la
                    planificación de proyectos es prioritaria dentro de nuestro
                    flujo de trabajo.
                  </p>
                </div>

                <div className="col-xl-12">
                  <p>
                    Realizamos una planificación exhaustiva, incluyendo un grupo
                    de trabajo dirigidos por un experimentado jefe de proyecto,
                    detalles de diseño y un completo programa para asegurar la
                    satisfacción del cliente.
                  </p>
                  <p>
                    Trabaja junto a nuestros equipos para encaminar tu proyecto
                    en la ruta del éxito.
                  </p>
                </div>
              </div>
            </Slide>

            <Slide id="solucion" nextSlide="#visita">
              <div className="row">
                <div className="text-center">
                  <h2>
                    ¿Es la energía renovable la solución <b>correcta</b> para tu
                    organización?
                  </h2>
                  <h4>
                    Nuestros equipos integrados por expertos pueden asesorar a
                    lo largo de todas las etapas de desarrollo de un proyecto
                    solar
                  </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6 col-xl-3">
                  <div className="feature_content">
                    <GatsbyImage
                      className="mx-auto"
                      image={site_survey}
                      alt="Prospecciones de sitios para paneles solares"
                    />
                    <h5>Anteproyectos</h5>
                    <ul className="bullet-list">
                      <li>Factibilidad de Sitios</li>
                      <li>Estudios solares</li>
                      <li>Análisis de producción solar eléctrica</li>
                      <li>Análisis de evacuación eléctrica</li>
                      <li>Análisis de accesos</li>
                      <li>Impacto ambiental</li>
                      <li>Impacto social y comunitario</li>
                      <li>Estudios de mitigación de riesgos</li>
                      <li>
                        Determinación económica de los proyectos, VAN, TIR, PB
                        análisis de sensibilidad, Inversión esperada
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-3">
                  <div className="feature_content">
                    <GatsbyImage
                      className="mx-auto"
                      image={engineering}
                      alt="Ingeniería de proyectos paneles solares"
                    />
                    <h5>Ingeniería</h5>
                    <ul className="bullet-list">
                      <li>Ingeniería básica</li>
                      <li>Ingeniería de detalle</li>
                      <li>Proyectos definitivos</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-3">
                  <div className="feature_content">
                    <GatsbyImage
                      className="mx-auto"
                      image={financing}
                      alt="Regularizaciones paneles solares SEC"
                    />
                    <h5>Regularización S.E.C.</h5>
                    <ul className="bullet-list">
                      <li>
                        Ajuste de las instalaciones construidas a la normativa
                        vigente
                      </li>
                      <li>
                        Preparación de la ingeniería, reportes y planos exigidos
                        por la autoridad fiscalizadora
                      </li>
                      <li>
                        Preparación de todos los formularios y su posterior
                        tramitación ante los organismos competentes
                      </li>
                      <li>Entrega de las plantas regularizadas</li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-3">
                  <div className="feature_content">
                    <GatsbyImage
                      className="mx-auto"
                      image={audit}
                      alt="Auditorías energéticas"
                    />
                    <h5>Auditorías Eléctricas</h5>
                    <ul className="bullet-list">
                      <li>
                        Realización de levantamientos eléctricos a los sistemas
                        de potencia y alumbrado de las empresas con sus planos
                        correspondientes
                      </li>
                      <li>
                        Medición y análisis de los procesos de operación de cada
                        empresa
                      </li>
                      <li>
                        Diagnóstico de errores en operación o equipos obsoletos
                        energéticamente
                      </li>
                      <li>
                        Preparación de un reporte de auditoría para reducir el
                        consumo energético de la empresa, con un mínimo de
                        inversión
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slide>

            <Slide id="visita" className="small" isLastSlide={true}>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="text-center">
                  <h2>
                    Si requieres <b>ingeniería</b> para ahorrar o ganar dinero
                  </h2>
                  <h4>Programa una visita</h4>
                  <a href="\#" className="btn btn-primary" onClick={this.click}>
                    Agendar
                  </a>
                </div>
              </div>
            </Slide>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}

function Page(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          jumbo: file(relativePath: { eq: "asesorias.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          project_planning: file(
            relativePath: { regex: "/project_planning.jpg/" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          engineering: file(relativePath: { regex: "/engineering.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          site_survey: file(relativePath: { regex: "/site_survey.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          financing: file(relativePath: { regex: "/financing.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          audit: file(relativePath: { regex: "/audit.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      `}
      render={data => (
        <Asesorias
          jumbo={data.jumbo}
          project_planning={data.project_planning}
          site_survey={data.site_survey}
          engineering={data.engineering}
          financing={data.financing}
          audit={data.audit}
          {...props}
        />
      )}
    />
  )
}
export default Page
